// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_7R-o9{background-color:#f0f;display:flex;flex-direction:column;overflow:auto;width:100%}.rounded_1AcGd{border-radius:var(--border-radius-large)}.shadow_6m7AA{box-shadow:var(--box-shadow-small)}.outline_SiIqx{border:1px solid var(--color-accent-200)}.explodeHeight_hlZIp{height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_7R-o9",
	"rounded": "rounded_1AcGd",
	"shadow": "shadow_6m7AA",
	"outline": "outline_SiIqx",
	"explodeHeight": "explodeHeight_hlZIp"
};
module.exports = ___CSS_LOADER_EXPORT___;
