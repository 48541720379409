
import Timeline from "./components/Timeline";
import Typography from "./components/Typography";
import Buttons from "./components/Buttons";
import Icons from "./components/Icons";
import DemoTable from "./components/DemoTable";

import SegmentedDropDown from "../portfolio/components/SegmentedDropDown/SegmentedDropDown.vue";

export default {
  layout: "testbed",
  components: {
    Typography,
    Timeline,
    Buttons,
    Icons,
    DemoTable,
    SegmentedDropDown,
  },

  data() {
    return {
      labelBefore: false,
      labelAfter: true,
      toggleButton: 1,
      sliderValue: 0,
      dropdown: 1,
      radioButton: "3",
      progress: 0,
      datePicker: "",
      sliderRangeValue: [0, 10],
      sliderRangeMinMax: [0, 10],
      slideRangeValueStep: 1,

      sparkline: false,

      exampleDataA: {
        xaxis: {
          categories: ["Feburar", "March", "April", "May", "June", "July", "August", "Sepember", "October"],
        },

        series: [
          {
            name: "Net Profit",
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          },
        ],
      },

      exampleDataB: {
        xaxis: {
          categories: ["Feburar", "March", "April", "May", "June", "July", "August", "Sepember", "October"],
        },

        series: [
          {
            name: "Revenue",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          },
        ],
      },

      exampleDataC: {
        xaxis: {
          categories: ["Feburar", "March", "April", "May", "June", "July", "August", "Sepember", "October"],
        },

        series: [
          {
            name: "Free Cash Flow",
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
          },
        ],
      },

      exampleDataD: {
        xaxis: {
          categories: ["Feburar", "March", "April", "May", "June", "July", "August", "Sepember", "October"],
        },

        series: [
          {
            name: "Net Profit",
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          },

          {
            name: "Revenue",
            data: [44 * 1.25, 55 * 1.25, 57 * 1.25, 56 * 1.25, 61 * 1.25, 58 * 1.25, 63 * 1.25, 60 * 1.25, 66 * 1.25],
          },

          {
            name: "Free Cash Flow",
            data: [44 * 1.45, 55 * 1.35, 57 * 1.35, 56 * 1.55, 61 * 1.15, 58 * 1.15, 63 * 1.4, 60 * 1.4, 66 * 1.45],
          },
        ],
      },

      chartData: {
        labels: [2016, 2017, 2018, 2019, 2020, 2021, 2022],

        datasets: [
          {
            label: "linear trend",
            data: [1, 2, 3, 4, 5, 6, 7],
          },

          {
            label: "negative trend  ",
            data: [14, 13, 12, 11, 10, 9, 8],
          },

          {
            label: "fibonacci",
            data: [1, 2, 3, 5, 8, 13, 21],
          },

          {
            label: "random numbers",
            data: [1 + 3, 2 + 1, 3 + 30, 5 + 4, 8 + 4, 13 + 1, 21 + 3],
          },
        ],
      },

      salesStatus: 3,
    };
  },

  computed: {
    filter() {
      return filter;
    },
  },

  async mounted() {
    setInterval(() => {
      this.progress = Math.floor(Math.random() * 100) + 1;
    }, 500);
  },

  methods: {
    radioButtonChange(value) {
      this.radioButton = value;
    },

    dropDownLabel(value) {
      if (value == 1) return "Order by name";
      if (value == 2) return "Order by sirname";
      if (value == 3) return "Order by middle name";
    },

    salesStatusLabel(salesStatus) {
      switch (salesStatus) {
        case 0:
          return "Select status…";

        case 1:
          return "Preparing for sale";

        case 2:
          return "For sale";

        case 3:
          return "Offer accepted";

        case 4:
          return "SPA binding";

        case 5:
          return "Handed over";

        case 6:
          return "Completed";
      }
    },
    sliderValueUpdate(v) {
      this.sliderValue = v;
    },

    sliderRangeValueUpdate(v) {},
  },
};
